import { useEffect, useState } from 'react'
import { API, ws } from '../../config'
import AgendaD1 from '../../img/room/agendaDia1.png'
import AgendaD2 from '../../img/room/agendaDia2.png'

import './agenda.css'

let Agenda = (props) => {
  const [agenda1, setAgenda1] = useState(true)
  const [agenda2, setAgenda2] = useState(false)

  const [imgDia1, setImgDia1] = useState(null)
  const [imgDia2, setImgDia2] = useState(null)

  const clickAgenda1 = (evt) => {
    setAgenda1(true)
    setAgenda2(false)
  }

  const clickAgenda2 = (evt) => {
    setAgenda1(false)
    setAgenda2(true)
  }

  useEffect(() => {

    fetch(`${API}/custom-images/imgDia1`)
      .then(res => res.json())
      .then(res => {
        setImgDia1(API + res.imagePath)
      })

    fetch(`${API}/custom-images/imgDia2`)
      .then(res => res.json())
      .then(res => {
        setImgDia2(API + res.imagePath)
      })

    return () => {
      // Unmount
    }
  }, [])

  return (
    <div className="agendaCont">
      <div className="btnMenu">
        <button className={agenda1 ? "btnAgenda1 dia1 selected" : "btnAgenda1 dia1"} onClick={e => {clickAgenda1(e)}}>Día 1</button>
        <button className={agenda2 ? "btnAgenda2 dia2 selected" : "btnAgenda2 dia2"} onClick={e => {clickAgenda2(e)}}>Día 2</button>
      </div>
      {
        agenda1
        ? <div className="imgagendaDia" style={{ backgroundImage: `url(${imgDia1})` }}></div>
        : <div className="imgagendaDia" style={{ backgroundImage: `url(${imgDia2})` }}></div>
      }
    </div>
  )
}

export default Agenda
