import React, { useState, useEffect } from "react";
import { API, ws } from '../../config'
import io from "socket.io-client";
import Cookies from 'universal-cookie';


const socket = io.connect(ws, { transports: ['websocket'] });

let Stream = (props) => {
  let { user } = props
  const cookies = new Cookies();
  const [iframeSocket, setIframeSocket] = useState(null)
  const [iframeDB, setIframeDB] = useState(null)
  const [zoomBtn, setZoomBtn] = useState(false)
  const [urlBtn, setUrlBtn] = useState('')


  useEffect(() => {

    socket.on("streaming", data => {
      setIframeSocket(data.msg);
    });

    socket.on('user-list', (users) => {
      var countUID = 0;
      for (const userL in users) {
        console.log(users[userL].uID, user.userID);
        if (users[userL].uID === `${user.userID}`) {
          countUID++;
        }
      }

      if (countUID > 1) {
        console.log("Duplicado Room");
        cookies.remove('sessionUser')
        window.location.href = "/";
      }
    });

    let conf = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
    };

    fetch(`${API}/iframe/iframeDB`, conf)
      .then(res => res.json())
      .then(res => {
        setIframeDB(res.iframe)
      })

    return () => { socket.off() }
  }, []);

  return (
    <React.Fragment>
      {
        iframeSocket
        ? <div className="iframeCont" dangerouslySetInnerHTML={{__html: iframeSocket}}></div>
        : <div className="iframeCont" dangerouslySetInnerHTML={{__html: iframeDB}}></div>
      }
      {
        zoomBtn &&
        <a className="zoomBtn" href={urlBtn} target="_blank" rel="noopener noreferrer">
          <div className="imgZoom"></div>
          <p>Unirse a Zoom</p>
        </a>
      }
    </React.Fragment>
  )
}

export default Stream;
