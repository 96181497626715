import { useState } from "react"

let InputText = (props) => {
  const { text: [text, setText] } = { text: useState(''), ...(props.state || {}) }

  return (
    <div className={"inputContainer " + props.className}>
      <input className={props.className} required type={props.type} id={props.id} placeholder={props.placeholder || "Campo por llenar"} value={text} onChange={e => {setText(e.target.value)}}/>
    </div>
  )
}

export default InputText
