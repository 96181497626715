import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import Form from './form/form'
import Cookies from 'universal-cookie';

import './login.css'

let Login = (props) => {
  const cookies = new Cookies();
  let user = cookies.get('sessionUser')

  let phone = "3023180804"
  let country = "+57"
  let urlHelp = `https://wa.me/${country}${phone}`

  useEffect(() => {

    document.title = "Faes farma | Log In"

    return () => {
      // UnMount
    }
  }, [])

  if (user !== undefined) {
    return (
      <Redirect to="/lobby"/>
    )
  }

  return (
    <div className="loginContainer">
      <a href="https://www.teleperformance.com/es-es/" target="_blank" rel="noopener noreferrer"><div className="imgLogo"></div></a>
      <div className="container" >
        <div className="designs">
          <div className="logoFaes"></div>
          <div className="logoD"></div>
          <div className="hidrofe"></div>
        </div>
        <div className="forms">
          <Form sendText="Acceder"/>
        </div>
      </div>
      <a className="soporte" href={urlHelp} target="_blank" rel="noopener noreferrer"></a>
    </div>
  )
}

export default Login
