import { useState } from "react"
import manIcon from '../../../img/lobby/speakers/manIcon.png'
import WomenIcon from '../../../img/lobby/speakers/womenIcon.png'
import BisIcon from '../../../img/lobby/speakers/bisIcon.png'

import './speakers.css'

let Speakers = (props) => {
  let date = new Date()
  let selectDay = {month: date.getMonth(), day: date.getDate()}

  const [day] = useState(selectDay.month <= 5 && selectDay.day <= 1 ? "day1" : "day2")

  let salasDay1 = [
    {number: 1, title: "sala 1", speaker: "Dra. Luisa", subtitle: "Tu conocimiento de Hidroferol  te premia", image: WomenIcon},
    {number: 2, title: "sala 2", speaker: "Maritza", subtitle: "Conceptos de Mercadeo Hidroferol", image: WomenIcon},
    {number: 3, title: "sala 3", speaker: "Sandra y Nelsy", subtitle: "Estrategia Comercial Hidroferol", image: WomenIcon},
    {number: 4, title: "sala 4", speaker: "Ivonne", subtitle: "Planteamiento Estratégico Hidroferol", image: WomenIcon},
    {number: 5, title: "sala 5", speaker: "Edgar, Diana y Paula", subtitle: "Manejo de técnica de ventas con Hidroferol", image: BisIcon},
  ]

  let salasDay2 = [
    {number: 1, title: "sala 1", speaker: "Edgar Santamaria / Claudia Echeverry", subtitle: "RPM Grupo No. 1", image: BisIcon},
    {number: 2, title: "sala 2", speaker: "Diana Murillas  / Sandra Arenas", subtitle: "RPM Grupo No. 2", image: WomenIcon},
    {number: 3, title: "sala 3", speaker: "Maritza Jaramillo", subtitle: "RPM Grupo No. 3", image: WomenIcon},
    {number: 4, title: "sala 4", speaker: "Paula Gonzalez / German Vargas", subtitle: "RPM Grupo No. 4", image: BisIcon},
    {number: 5, title: "sala 5", speaker: "Carmen Duran / Rodrigo Posada", subtitle: "RPM Grupo No. 5", image: BisIcon},
    {number: 6, title: "sala 6", speaker: "Dra. Luisa Parada / Jessica Echavarria", subtitle: "RPM Grupo No. 6", image: WomenIcon},
    {number: 7, title: "sala 7", speaker: "Ivonne Nassar / Jazmin Perez", subtitle: "Equipo Comercial No. 7", image: WomenIcon},
    {number: 8, title: "sala 8", speaker: "Sandra Meneses / Oscar Romero", subtitle: "Equipo Comercial No. 8", image: BisIcon},
    {number: 9, title: "sala 9", speaker: "Nelsy Hernandez / Adriana Jurado", subtitle: "Equipo Comercial No. 9", image: WomenIcon},
    {number: 10, title: "sala 10", speaker: "Henry Forero / Johanna Castellanos", subtitle: "Mercaderistas 10", image: BisIcon},
  ]
  // {number: 11, title: "sala 11", speaker: "Gustavo Gomez", subtitle: "Institucional  11", image: manIcon},

  return (
    <div className="speakerCont">
      {
        [day === "day1" ? salasDay1 : salasDay2][0].map((ele, idx) => {
          return (
            <form action="https://wp.ozelproducciones.co/validando-acceso/" method="post" key={idx}>
              <input type="hidden" name="ozelname" value={props.user.userName}/>
              <input type="hidden" name="logoutURL" value="https%3A%2F%2Feventofaesfarma.com%2Froom" />
              <input type="hidden" name="welcome" value="%3Cp%3EBienvenidos+a+su+Sala+de+Ozel.+%F0%9F%98%8E%F0%9F%98%8E+%C2%A1Ahora+puedes+disfrutar+de+la+Experiencia+Virtual+Definitiva%21+%F0%9F%A5%BD%F0%9F%A5%BD%3C%2Fp%3E%0A" />
              <input type="hidden" name="logo" value="" />
              <input type="hidden" name="customStyleUrl" value="https://wp.ozelproducciones.co/wp/app-theming/Farma/standard.css" />
              <input type="hidden" name="backgroundUrl" value="/app/uploads/2021/05/backgroundImg-little.jpg" />
              <input type="hidden" name="ozelrole" value="ap" />
              <input type="hidden" name="ozelid" value={`Sala+%23${ele.number}+Faes+Farma`} />
              <input type="hidden" name="host" value={`sala${ele.number}.eventofaesfarma.com`} /> {/* value={`sala${ele.number}.eventofaesfarma.com`} */}
              <input type="hidden" name="userID" value={props.user.userID} />
              <button type="submit" className="buttonRoom">
                <div className="speaker" key={idx}>
                  <div className="image" style={{ backgroundImage: `url("${ele.image}")` }}>
                    <div className="title">{ele.title}</div>
                  </div>
                  <div className="info">
                    <h2 className="name">{ele.speaker}</h2>
                    <p className="desc">{ele.subtitle}</p>
                  </div>
                </div>
              </button>
            </form>
          )
        })
      }
    </div>
  )
}

export default Speakers