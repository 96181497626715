import { useState, useEffect } from 'react'
import { API } from '../../../config'

import './online.css'

let OnlineBBB = (props) => {
  let date = new Date()
  let selectDay = {month: date.getMonth(), day: date.getDate()}
  const [room1, setRoom1] = useState(false)
  const [room2, setRoom2] = useState(false)
  const [room3, setRoom3] = useState(false)
  const [room4, setRoom4] = useState(false)
  const [room5, setRoom5] = useState(false)
  const [room6, setRoom6] = useState(false)
  const [room7, setRoom7] = useState(false)
  const [room8, setRoom8] = useState(false)
  const [room9, setRoom9] = useState(false)
  const [room10, setRoom10] = useState(false)
  const [room11, setRoom11] = useState(false)

  const [online, setOnline] = useState([])

  useEffect(() => {

    return () => {}
  }, [])

  let handleAttendee = (evt) => {
    evt.preventDefault()

    fetch(`${API}/bbb/meeting/attendee?host=sala${1}.eventofaesfarma.com&meetingID=Sala%20%23${1}%20Faes%20Farma`)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          setRoom1(false)
        } else {
          setRoom1(true)
        }
      })

    fetch(`${API}/bbb/meeting/attendee?host=sala${2}.eventofaesfarma.com&meetingID=Sala%20%23${2}%20Faes%20Farma`)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          setRoom2(false)
        } else {
          setRoom2(true)
        }
      })

    fetch(`${API}/bbb/meeting/attendee?host=sala${3}.eventofaesfarma.com&meetingID=Sala%20%23${3}%20Faes%20Farma`)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          setRoom3(false)
        } else {
          setRoom3(true)
        }
      })

    fetch(`${API}/bbb/meeting/attendee?host=sala${4}.eventofaesfarma.com&meetingID=Sala%20%23${4}%20Faes%20Farma`)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          setRoom4(false)
        } else {
          setRoom4(true)
        }
      })

    fetch(`${API}/bbb/meeting/attendee?host=sala${5}.eventofaesfarma.com&meetingID=Sala%20%23${5}%20Faes%20Farma`)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          setRoom5(false)
        } else {
          setRoom5(true)
        }
      })

    fetch(`${API}/bbb/meeting/attendee?host=sala${6}.eventofaesfarma.com&meetingID=Sala%20%23${6}%20Faes%20Farma`)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          setRoom6(false)
        } else {
          setRoom6(true)
        }
      })

    fetch(`${API}/bbb/meeting/attendee?host=sala${7}.eventofaesfarma.com&meetingID=Sala%20%23${7}%20Faes%20Farma`)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          setRoom7(false)
        } else {
          setRoom7(true)
        }
      })

    fetch(`${API}/bbb/meeting/attendee?host=sala${8}.eventofaesfarma.com&meetingID=Sala%20%23${8}%20Faes%20Farma`)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          setRoom8(false)
        } else {
          setRoom8(true)
        }
      })

    fetch(`${API}/bbb/meeting/attendee?host=sala${9}.eventofaesfarma.com&meetingID=Sala%20%23${9}%20Faes%20Farma`)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          setRoom9(false)
        } else {
          setRoom9(true)
        }
      })

    fetch(`${API}/bbb/meeting/attendee?host=sala${10}.eventofaesfarma.com&meetingID=Sala%20%23${10}%20Faes%20Farma`)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          setRoom10(false)
        } else {
          setRoom10(true)
        }
      })

    fetch(`${API}/bbb/meeting/attendee?host=sala${11}.eventofaesfarma.com&meetingID=Sala%20%23${11}%20Faes%20Farma`)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          setRoom11(false)
        } else {
          setRoom11(true)
        }
      })
  }

  if (room1 && room2 /* && room3 && room4 && room5 && room6 && room7 && room8 && room9 && room10 && room11 */) {
    fetch(`${API}/bbb`)
      .then(res => res.json())
      .then(res => {

        setOnline(res.reverse())

        setRoom1(false)
        setRoom2(false)
        setRoom3(false)
        setRoom4(false)
        setRoom5(false)
        setRoom6(false)
        setRoom7(false)
        setRoom8(false)
        setRoom9(false)
        setRoom10(false)
        setRoom11(false)
      })
  }

  return (
    <div className="BBBCont">
      <p className="warn">
        La información completa está en la Base de Datos <br />
        Hay {online.length} registros
      </p>
      <div className="day">
        <button onClick={e => {handleAttendee(e)}}>Consultar y guardar</button>
        <div className="listContainer">
          <div className="header">
            <h2>Nombre de la sala</h2>
            <h2>Participantes</h2>
          </div>
          <div className="infoCont">
            {
              online.map((ele, idx) => {
                return (
                  <div className="item" key={idx}>
                    <h4 className="name">{ele.meeting.meetingID}</h4>
                    <h4 className="cantidad">{ele.attendees.length}</h4>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default OnlineBBB
