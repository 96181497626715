import { useEffect, useState } from 'react'
import Chat from '../../components/chat/chat'
import { API, ws } from '../../config'
import io from "socket.io-client";

const socket = io.connect(ws, { transports: ['websocket'] });

let Kahoot = (props) => {
  let user = props.user
  const [kBtn, setKbtn] = useState(true)
  const [iframeCont, setIframeCont] = useState(false)
  const [urlBtn, setUrlBtn] = useState('https://kahoot.it/')
  const [msgChat, setMsgChat] = useState([])

  useEffect(() => {

    socket.on("zoomBtn", (data) => {
      setKbtn(true)
      setUrlBtn(data.url)
    });

    return () => { }
  }, [])


  return (
    <div className="kahootCont">
      {
        kBtn
        ? <div className="tabs">
            <button className={`chatBtn ${!iframeCont ? "clicked" : ""}`} onClick={e => {setIframeCont(false)}}>Chat</button>
            <button className={`kbtn ${iframeCont ? "clicked" : ""}`} onClick={e => {setIframeCont(true)}}>Kahoot</button>
          </div>
        : <div className="tabs">
            <button className="chatBtn clicked" onClick={e => {setIframeCont(false)}}>Chat</button>
            <button disabled className="kbtn" onClick={e => {setIframeCont(true)}}>Kahoot</button>
          </div>
      }
      {
        iframeCont && kBtn
        ? <iframe className="iK" src={urlBtn} frameBorder="0" scrolling="no" allowFullScreen></iframe>
        : <Chat socket={props.socket} user={user || {}} type="chat" state={{ chatMsg: [msgChat, setMsgChat] }}/>
      }
    </div>
  )
}

export default Kahoot
