import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import Stream from '../../components/stream/stream'
import ModalRooms from '../../components/modalRoms/modalRooms'
import Cookies from 'universal-cookie';
import { ws } from '../../config'
import io from "socket.io-client";
import Agenda from '../../components/agenda/agenda'

import './room.css'
import Kahoot from '../../components/kahoot/kahoot';

const socket = io.connect(ws, { transports: ['websocket'] });

let Room = (props) => {
  const cookies = new Cookies();
  let date = new Date()
  let selectDay = {month: date.getMonth(), day: date.getDate()}

  const [iframClient] = useState('')
  const [back, setBack] = useState(false)
  const [rooms, setRooms] = useState(false)
  const [agendaBtn, setAgendaBtn] = useState(false)

  let user = cookies.get('sessionUser')

  const [day, setDay] = useState(selectDay.month <= 5 && selectDay.day < 1 ? "day1" : "day2")

  useEffect(() => {
    if (user !== undefined) {
      document.title = "Faes Farma | Room"

      socket.emit('onload', 'onload'); //onConnect
      socket.emit('new-user', {
        name: user.userName,
        uID: user.userID,
      });

      socket.on('user-list', (users) => {
        console.log('Socket - Room');
        var countUID = 0;
        for (const userL in users) {
          console.log(users[userL].uID, user.userID);
          if (users[userL].uID === `${user.userID}`) {
            countUID++;
          }
        }

        if (countUID > 1) {
          console.log("Duplicado Room");
          cookies.remove('sessionUser')
          window.location.href = "/";
        }
      });
    }

    return () => {
      socket.off()
      socket.close()
    }
  }, [])

  if (user === undefined) {
    return (
      <Redirect to="/"/>
    )
  }

  if (back) {
    window.location.href = "/lobby";
  }

  let closeModal = (evt) => {
    if (evt.className === "close" || evt.className === "modalContainerA") {
      setAgendaBtn(false)
    }
  }

  return (
    <div className="roomContainer">

      {
        rooms &&
        <ModalRooms state={{ roomsBtn: [rooms, setRooms] }}/>
      }

      {
        agendaBtn &&
        <div className="modalContainerA" onClick={e => {closeModal(e.target)}}>
          <button className="close">X</button>
          <div className="scrollCont">
            <div className="modalContent">
              <Agenda />
            </div>
          </div>
        </div>
      }

      <div className="menu">
        <button className="back" onClick={e => {setBack(!back)}}>Regresar</button>
        <button className="agenda" onClick={e => {setAgendaBtn(true)}}>Agenda</button>
      </div>

      <div className="sections">
        <div className="stream">
          <div className="logos"></div>
          <div className="player">
            <Stream socket={socket} iframeSock={iframClient} user={user} />
          </div>
        </div>

        <div className="interactions">
          <div className="title">
            <h1>CONVENCIÓN NACIONAL VIRTUAL</h1>
            <h2>FAES FARMA</h2>
          </div>

          <Kahoot socket={socket} user={user}/>

          <div className="salasBtn">
            <p>Grupo {day === "day1" ? user.roomDay1 : user.roomDay2}</p>
            <button className="salas" onClick={e => {setRooms(!rooms)}}>Salas</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Room
