const modeEnv = process.env.NODE_ENV || 'development';
let port = ''

// check if mode is in developement or production (Build)
if (modeEnv === 'development') port = process.env.REACT_APP_BACKEND_PORT_API
if (modeEnv === 'production') port = ''

export const API = window.location.protocol + '//' + window.location.hostname + process.env.REACT_APP_BACKEND_PORT_API
export const ws = window.location.protocol + "//" + window.location.hostname + port


