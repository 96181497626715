import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Login from '../login/login'
import Agenda from './agenda/agenda'
import { ws } from '../../config'
import io from "socket.io-client";

import Speakers from './speakers/speakers'
import './lobby.css'

const socket = io.connect(ws, { transports: ['websocket'] });

let Lobby = (props) => {
  let date = new Date()
  let selectDay = {month: date.getMonth(), day: date.getDate()}

  const cookies = new Cookies();
  const [room, setRoom] = useState(false)
  const [welcome, setWelcome] = useState(true)
  const [day, setDay] = useState(selectDay.month <= 5 && selectDay.day <= 1 ? "day1" : "day2")

  let mayo13 = [
    {time: "7:30 am - 8:00 am", title: "Ingreso a la plataforma", place: "Sala principal"},
    {time: "8:00 am - 9:31 am", title: "Inicio Convención Nacional Faes Farma 2021 - Día 1", place: "Sala principal"},
    {time: "9:31 am - 9:45 am", title: "Descanso", place: "Sala principal"},
    {time: "9:45 am - 10:00 am", title: "Primer juego Kahoot", place: "Sala principal"},
    {time: "10:00 am - 10:45 am", title: "Charla 1:  Sesiones en salas.", place: "5 SALAS SIMULTÁNEAS", groups: [
      "- Sala No. 1: Grupo No. 1",
      "- Sala No. 2: Grupo No. 2",
      "- Sala No. 3: Grupo No. 3",
      "- Sala No. 4: Grupo No. 4",
      "- Sala No. 5: Grupo No. 5",
    ]},
    {time: "10:45 am - 11:00 am", title: "Segundo juego Kahoot", place: "Sala principal"},
    {time: "11:00 am - 11:45 am", title: "Charla 2:  Sesiones en salas.", place: "5 SALAS SIMULTÁNEAS", groups: [
      "- Sala No. 1: Grupo No. 5",
      "- Sala No. 2: Grupo No. 1",
      "- Sala No. 3: Grupo No. 2",
      "- Sala No. 4: Grupo No. 3",
      "- Sala No. 5: Grupo No. 4",
    ]},
    {time: "11:45 am - 12:00 pm", title: "Tercer juego Kahoot", place: "Sala principal"},
    {time: "12:00 pm - 12:45 pm", title: "Charla 3: Sesiones en salas.", place: "5 SALAS SIMULTÁNEAS", groups: [
      "- Sala No. 1: Grupo No. 4",
      "- Sala No. 2: Grupo No. 5",
      "- Sala No. 3: Grupo No. 1",
      "- Sala No. 4: Grupo No. 2",
      "- Sala No. 5: Grupo No. 3",
    ]},
    {time: "12:45 pm - 1:00 pm", title: "Cuarto juego Kahoot", place: "Sala principal"},
    {time: "1:00 pm - 2:00 pm", title: "Descanso Transmisión de Cortinilla de Hidroferol", place: "Sala principal"},
    {time: "2:00 pm - 2:45 pm", title: "Charla 4: Sesiones en salas.", place: "5 SALAS SIMULTÁNEAS", groups: [
      "- Sala No. 1: Grupo No. 3",
      "- Sala No. 2: Grupo No. 4",
      "- Sala No. 3: Grupo No. 5",
      "- Sala No. 4: Grupo No. 1",
      "- Sala No. 5: Grupo No. 2",
    ]},
    {time: "2:45 pm - 3:00 pm", title: "Quinto juego Kahoot", place: "Sala principal"},
    {time: "3:00 pm - 3:15 pm", title: " Descanso Transmisión de Cortinilla de Hidroferol", place: "Sala principal"},
    {time: "3:15 pm - 4:00 pm", title: "Charla 5: Sesiones en salas.", place: "5 SALAS SIMULTÁNEAS", groups: [
      "- Sala No. 1: Grupo No. 2",
      "- Sala No. 2: Grupo No. 3",
      "- Sala No. 3: Grupo No. 4",
      "- Sala No. 4: Grupo No. 5",
      "- Sala No. 5: Grupo No. 1",
    ]},
    {time: "4:00 pm - 4:16 pm", title: "Sexto juego Kahoot", place: "Sala principal"},
    {time: "4:16 pm - 6:00 pm", title: "Trabajo por Distritos y fin de evento", place: "Sala principal"},
  ]

  let mayo14 = [
    {time: "7:30 am - 8:00 am", title: "Inicio Convención Nacional Faes Farma 2021 - Día 2", place: "Sala principal"},
    {time: "8:30 am - 9:20 am", title: "Explicación de Dinámica de grupos", place: "Sala principal"},
    {time: "9:20 am - 10:16 am", title: "Clínicas de Visitas Distribución en salas de acuerdo a la asignación", place: "10 SALAS (SALA ASIGNADA)"},
    {time: "10:16 am - 10:30 am", title: "Descanso", place: "Sala principal"},
    {time: "10:30 am - 10:45 am", title: "Primer Kahoot", place: "Sala principal"},
    {time: "10:45 am - 11:45 am", title: "Clínicas de Visitas distribución en Salas de acuerdo a la asignación", place: "10 SALAS (SALA ASIGNADA)"},
    {time: "11:45 am - 12:00 pm", title: "Segundo Kahoot", place: "Sala principal"},
    {time: "12:00 pm - 1:30 pm", title: "Clínicas de Visitas distribución en Salas de acuerdo a la asignación", place: "10 SALAS (SALA ASIGNADA)"},
    {time: "1:30 pm - 2:30 pm", title: "Almuerzo Transmisión de Cortinilla de Hidroferol", place: "Sala principal"},
    {time: "2:30 pm - 2:45 pm", title: "Tercer Kahoot", place: "Sala principal"},
    {time: "2:45 pm - 3:45 pm", title: "Clínicas de Visitas distribución en Salas de acuerdo a la asignación", place: "10 SALAS (SALA ASIGNADA)"},
    {time: "3:45 pm - 5:15 pm", title: "Descanso", place: "Sala principal"},
    {time: "5:15 pm - 5:30 pm", title: "Regreso a sala principal", place: "Sala principal"},
    {time: "5:30 pm - 6:30 pm", title: "Premiación", place: "Sala principal"},
    {time: "6:30 pm - 7:30 pm", title: "HAPPYTALISMO", place: "Sala principal"},
    {time: "7:30 pm - 8:30 pm", title: "Cierre del evento (Brindis)", place: "Sala principal"},
  ]

  let user = cookies.get('sessionUser')

  useEffect(() => {
    if (user !== undefined) {
      document.title = "Faes Farma | Lobby"

      socket.emit('onload', 'onload'); //onConnect
      socket.emit('new-user', {
        name: user.userName,
        uID: user.userID,
      });


      socket.on('user-list', (users) => {
        var countUID = 0;
        for (const userL in users) {
          console.log(users[userL].uID, user.userID);
          if (users[userL].uID === `${user.userID}`) {
            countUID++;
          }
        }

        if (countUID > 1) {
          console.log("Duplicado Lobby");
          cookies.remove('sessionUser')
          window.location.href = "/";
        }
      });
    }

    return () => {
      socket.off()
      socket.close()
    }
  }, [])

  if (user === undefined) {
    return (
      <Redirect to="/"/>
    )
  }

  if (room) {
    window.location.href = "/room";
  }

  let logOut = (evt) => {
    cookies.remove('sessionUser')
    window.location.href = "/";
  }

  let closeModal = (evt) => {
    if (evt.className === "close" || evt.className === "modalContainer") {
      setWelcome(false)
    }
  }

  return (
    <div className="lobbyContainer">

      {
        welcome &&
        <div className="modalContainer" onClick={e => {closeModal(e.target)}}>
          <button className="close">X</button>
          <div className="scrollCont">
            <div className="modalContent">
              <h2><span>Bienvenidos a <br /> </span>Faes Farma</h2>
              <h3>Tu grupo asignado es el <span>#{day === "day1" ? user.roomDay1 : user.roomDay2}</span></h3>
            </div>
          </div>
        </div>
      }

      <button className="logout" onClick={e => {logOut(e)}}>Cerrar Sesión</button>

      <div className="sections">
        <div className="principal">
          <div className="title">
            <h1>CONVENCIÓN NACIONAL VIRTUAL</h1>
            <h2>FAES FARMA</h2>
          </div>
          {/* <span className="separator"></span> */}
          <button className="mainRoom" onClick={e => {setRoom(!room)}}>Sala Principal</button>
        </div>

        <div className="dataCont">
          <div className="agenda">
            <Agenda day="Junio 1" content={mayo13}/>
            <Agenda day="Junio 2" content={mayo14}/>
          </div>
          <div className="speakers">
            <Speakers user={user} />
          </div>
        </div>

      </div>

      <div className="logos"></div>

    </div>
  )
}

export default Lobby
