import { useState, useEffect } from 'react'
import { jsPDF } from "jspdf"
import html2canvas from 'html2canvas'

import './certified.css'

let Certified = (props) => {
  const [popup, setPopup] = useState(false)
  const [names, setNames] = useState('')
  const [loadedNames, setLoadedNames] = useState([])
  const [number, setNumber] = useState(0)

  let closeAnyWay = (evt) => {
    if (evt.target.className === "close" || evt.target.className === "modalCont") {
      setPopup(false)
    }
  }

  let createPDF = (evt) => {
    const pdfCont = document.getElementById("pdfContent")
    const area = pdfCont.getBoundingClientRect()

    html2canvas(pdfCont, {
      scrollX: 0,
      scrollY: 0,
      width: area.width,
      height: area.height,
      useCORS: true,
    })
      .then(canvas => {
        let img = canvas.toDataURL("image/jpeg")

        const docPDF = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: [14, (14 / 1.7777)]
        });

        docPDF.addImage(img, 'JPEG', 0, 0, 14, (14 / 1.7777))
        docPDF.save(`certificado-${loadedNames[number]}.pdf`);
      })
  }

  let createListNames = (evt) => {
    evt.preventDefault()

    let list = names.split('\n')
    let finalList = []

    list.map((ele) => {
      finalList.push(ele.trim())
    })

    setLoadedNames(finalList)
    setNames('')
  }

  let selectNames =(evt) => {
    let actualNumber = number

    if (loadedNames.length > 0) {
      if (evt.target.className === "left") {
        if (number < 1) {
          setNumber(0)
        } else {
          actualNumber -= 1
          setNumber(actualNumber)
        }
      } else if (evt.target.className === "right") {
        if (number > (loadedNames.length - 2)) {
          setNumber(loadedNames.length - 1)
        } else {
          actualNumber += 1
          setNumber(actualNumber)
        }
      }
    }
  }


  return (
    <div className="certifiedCont">
      {
        popup &&
        <div className="modalCont" onClick={e => {closeAnyWay(e)}}>
          <div className="close">X</div>
          <div className="content">
            <div className="actions">
              <div className="left" onClick={e => {selectNames(e)}}></div>
              <button className="generateBtn" onClick={e => {createPDF(e)}}>Descargar {`${number}/${loadedNames.length === 0 ? 0 : loadedNames.length - 1}`}</button>
              <div className="right" onClick={e => {selectNames(e)}}></div>
            </div>

            <div className="pdfContent" id="pdfContent">
              <div className="image" id="image"></div>
              <h2 className="name" id="name">{loadedNames[number]}</h2>
            </div>
          </div>
        </div>
      }

      <form className="loadNameCont" onSubmit={e => {createListNames(e)}}>
        <textarea className="inputNames" type="text" placeholder="Cargar nombres" value={names} onChange={e => {setNames(e.target.value)}}></textarea>
        <button className="loadNames" type="submit">Cargar nombres</button>
      </form>

      <div className="generatorCont">
        <button className="generateBtn" onClick={e => {setPopup(true)}}>Generar certificados</button>

        <div className="generatedCertCont">
          {
            loadedNames.map((ele, idx) => {
              return (
                <div className="userCont" key={idx}>
                  <p className="userName">{ele}</p>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Certified
