import { useState } from "react"

let InputNumber = (props) => {
  const { number: [value, setValue] } = { number: useState(''), ...(props.state || {}) }

  return (
    <div className={"inputContainer " + props.className}>
      <input className={props.className} required type={props.type} id={props.id} placeholder={props.placeholder || "Campo por llenar"} value={value} onChange={e => {setValue(e.target.value)}}/>
    </div>
  )
}

export default InputNumber
