import './agenda.css'

let Agenda = (props) => {
  return (
    <div className="agendaContainer">
      <div className="titleCont">
        <h2>{props.day}</h2>
      </div>
      <div className="content">
        {
          props.content.map((ele, idx) => {
            return (
              <div className="Cont" key={idx}>
                <p className="time">{ele.time}</p>
                <h2 className="title">{ele.title}</h2>
                <span className="separator"></span>
                <p className="description">{ele.place}</p>
                {
                  ele.groups &&
                  ele.groups.map((places, idx) => {
                    return (
                      <p className="description" key={idx}>{places}</p>
                    )
                  })
                }
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Agenda
