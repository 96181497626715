import { useState } from "react"

import './checkBox.css'

let CheckBox = (props) => {
  const { tyc: [tyc, setTyc] } = { tyc: useState(''), ...(props.state || {}) }
  const [modal, setModal] = useState(false)

  let closeAnyWay = (evt) => {
    if (evt.className === "ModalCont") {
      setModal(false)
    }
  }

  return (
    <div className="checkBoxContainer">
      <label className="customCheckBox" htmlFor={props.id} onClick={e => {setTyc(!tyc)}}>
        <div className="checkCont">
          {
            tyc ? <span className="check ok">&#10004;</span> : <span className="check not">	&#10008;</span>
          }
        </div>
        <div className="textCont" onClick={e => {setModal(!modal)}}>
          {props.text}
        </div>
      </label>

      {
        modal &&
        <div className="ModalCont" onClick={e => {closeAnyWay(e.target)}}>
          <div className="close" onClick={e => {setModal(false)}}>X</div>
          <div className="modalstyle">
            <div className="modalInner">
              <div className="textModal">
                Autorizo a FAES FARMA a que realice la recolección, uso, y tratamiento de mis datos personales para fines comerciales según lo indicado en la Ley 1581 de 2012, Decreto 1377 de 2013, y en la política de tratamiento de datos personales disponible en <a href="https://faesfarma.com" target="_blank" rel="noopener noreferrer">https://faesfarma.com</a>.
                <br />
                <br />
                Toda la información que los usuarios provean al registro de la presente plataforma está sujeta a la Política de Privacidad de FAES FARMA, detallada en la página <a href="https://faesfarma.com/politica-de-privacidad/" target="_blank" rel="noopener noreferrer">https://faesfarma.com/politica-de-privacidad/</a>, misma que rige la forma en la cual FAES FARMA (o cualquiera de sus subsidiarias o filiales) recopila y emplea dicha información.
                <br />
                <br />
                Los usuarios aceptan y reconocen que al aceptar estos términos y condiciones otorgan su consentimiento a la recopilación, uso, tratamiento y/o transferencia de dicha información, en la forma establecida en la Política de Privacidad de FAES FARMA.
                <br />
                <br />
                He leído, entiendo y acepto los términos y condiciones de La Política de Privacidad de FAES FARMA detalladas en la página <a href="https://faesfarma.com/politica-de-privacidad/" target="_blank" rel="noopener noreferrer">https://faesfarma.com/politica-de-privacidad/</a> y por consiguiente, doy mi consentimiento al uso y tratamiento de mi información según lo descrito ahí.
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default CheckBox
